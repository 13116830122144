import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  InputBase,
} from "@mui/material";
import "./Resources.scss";
import Videos from "./Videos";
import { Search } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import copyIcon from "../../assets/images/copyIcon.svg";
import linkIcon from "../../assets/images/linkIcon.svg";

import APP_CONST from "../../Constants/app.constant";
import { toastNotify } from "../../Container/toastServices";

const Resources = () => {
  const [tabValue, setTabValue] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [sortingType, setSortingType] = useState("relevance");
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState("anchor link"); // anchor link

  const sortingRef = useRef(null);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    document.title = "Canvas.ai - Resource";
    document
      .querySelector(".content-area-wrap")
      .classList.remove("landingPageBG", "darkBG", "transparentBG");
    document.querySelector(".content-area-wrap").classList.add("darkBG");
  }, []);

  const clickOutsideSort = (e) => {
    if (sortingRef.current && !sortingRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideSort);
    return () => {
      document.removeEventListener("mousedown", clickOutsideSort);
    };
  }, []);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleSort = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (type) => {
    setSortingType(type);
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(APP_CONST.SALES_COLLATERALS)
      .then(() => {
        toastNotify(APP_CONST.COPIED_TO_CLIPBOARD, "success");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleOpen = () => {
    window.open(APP_CONST.SALES_COLLATERALS, "_blank");
  };

  return (
    <div className="resources-home">
      <div className="d-flex justify-content-between resource-tabs">
        <div className="sales d-flex align-center gap-3">
          <div className="sales-title ps-3 pe-1">
            Sales Collaterals:
            <img
              src={linkIcon}
              onClick={handleOpen}
              className="ps-3"
              alt="link icon"
            />
          </div>
          <div className="copy pe-3 ps-1">
            <img src={copyIcon} alt="copy icon" onClick={handleCopy} />
          </div>
        </div>
        <div className="search-sort-container d-flex justify-content-end align-items-center">
          <div className="d-flex justify-content-end search-bar">
            <InputBase
              className={`styledInputBase ${
                isMdDown ? "styledInputBaseAlignment" : "styledInputBaseAlign"
              }`}
              isMdDown={isMdDown}
              startAdornment={
                <Search
                  sx={{ color: "#C6E814", margin: "0px 8px 0px 16px" }}
                  fontSize="1.8vh"
                />
              }
              value={searchText}
              placeholder="Search"
              onChange={handleSearch}
            />
          </div>
          <div className="sort-by" onClick={handleSort} ref={sortingRef}>
            <span className="text">Sort by</span>
            <span className="down-arrow">
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </span>
          </div>
        </div>
      </div>
      <ul className={`dropdown-menu-head-resources ${isOpen ? "open" : ""}`}>
        <li
          onClick={() => {
            handleClick("relevance");
          }}
        >
          Relevance
        </li>
        <li onClick={() => handleClick("created")}>Created</li>
      </ul>
      {tabValue === 0 && (
        <Videos searchText={searchText} sortingType={sortingType} />
      )}
    </div>
  );
};

export default Resources;
